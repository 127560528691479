.App {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );

  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.card {
  padding: 32px;
  width: 430px;
  transition: all 0.2s ease-in;
  z-index: 5;
}

.glass {
  background: rgb(0 0 0 / 54%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(103 103 103 / 69%);
}

form {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

h1 {
  margin: 0;
}

.parrotExampleContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.parrotExample {
  height: 16px;
  width: 16px;
  padding: 4px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.49);
  transition: all 0.2s ease-in;
  animation: pop-in 0.2s ease-in forwards, move-across 30s ease-in both infinite;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes move-across {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100vw);
  }
}

.parrotExample img {
  height: 100%;
  width: 100%;
}

.button {
  display: block;
  margin-top: 32px;
  border: none;
  padding: 1em;
  outline: none;
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  font-style: 1.2em;
  letter-spacing: 0.2em;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: none;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.45);
}

.button:before,
.button:after {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 12px;
  transform: translateX(-50%) translateY(-50%);
  width: 105%;
  height: 105%;
  content: "";
  z-index: -2;
  background-size: 400% 400%;
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
}

.button:before {
  filter: blur(7px);
  transition: all 0.25s ease;
  -webkit-animation: pulse 10s infinite ease;
  animation: pulse 10s infinite ease;
}

.button:after {
  filter: blur(0.3px);
}

.button:hover:before {
  width: 106%;
  height: 106%;
}

.urlInput {
  padding: 1em;
  border-radius: 12px;
  background: rgb(255 255 255 / 12%);
  flex-grow: 1;
  border: 1px solid #ffffff8f;
  color: white;
  font-size: 16px;
  outline: 0;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.emphasis {
  font-weight: bold;
  background: linear-gradient(
    60deg,
    #fab46e,
    #f19886,
    #ff92b1,
    #cb8ed6,
    #98b8f8,
    #3cd6ee,
    #3aecd5,
    #a9f1bb
  );
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

[data-reach-tab-list] {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}

p {
  margin-top: 0;
}

header p {
  margin-top: 8px;
  color: #ffffffb8;
}

[data-reach-tab] {
  display: inline-block;
  border: none;
  padding: 0.25em 0.5em;
  margin: 0;
  border-bottom: 1px solid #ffffff4f;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  font-size: 16px;
  width: 100%;
  padding-bottom: 16px;
}

[data-reach-tab-panel] {
  padding-top: 32px;
}

[data-reach-tab][data-selected] {
  border-bottom-color: white;
}

.counter {
  margin-top: 12px;
}

pre {
  background: transparent !important;
  padding: 0px !important;
}

h3 {
  margin-top: 0;
}

.attribution {
  padding: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 12px;
  z-index: 5;
}

.attribution a {
  height: 1.2em;
  margin-left: 0.8ch;
}

.attribution img {
  height: 100%;
}

.parrotPreview {
  height: 32px;
  width: 32px;
}

.parrotPreviewContainer {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parrotPreviewContainer p {
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}

.urlContainer {
  box-sizing: border-box;
  width: 100%;
  margin-top: 12px;
  background: rgba(0, 0, 0, 0.45);
  padding: 12px 8px;
  padding-left: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.copyButton {
  width: 32px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
}

.parrotTypeOptionContainer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 505px) {
  .card {
    width: 90%;
    box-sizing: border-box;
  }

  .App {
    justify-content: unset;
    padding-top: 5%;
  }
}

.imageInputContainer {
  display: flex;
  column-gap: 24px;
}

input[type="file"] {
  display: none;
}

.customFileUpload {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.imagePreview {
  height: 32px;
  width: 32px;
}

.uploadIcon {
  height: 24px;
  width: 24px;
}

.uploadIconContainer {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
